<template>
	<div class="cookies-box container">
	    <p>Lo sentimos, el simulador de hipotecas necesita cookies para funcionar.</p>
	    <p>Si estás en modo incógnito te recomendamos que abras esta página web en una ventana normal.</p>
	    <p> Si prefieres seguir en modo incógnito, deberás habilitar las cookies en la configuración de tu navegador. Puedes encontrar información sobre cómo hacerlo <a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=es&co=GENIE.Platform%3DDesktop#zippy=%2Cpermitir-o-bloquear-las-cookies">aquí</a>.</p>
 	 </div>
</template>

<style scoped lang="scss">
	.cookies-box{
		font-size: 18px;
		font-weight: 600;
		border: 1px solid black;
		margin-top: 3rem;
		padding: 1rem;
	}
</style>